<template>
  <div id="service-suppliers">
    <v-container
      fluid
      class="pl-0 pr-0"
    >
      <Header />

      <Alert
        :showAlert="isErrorMessage"
        v-if="isErrorMessage"
        type="error"
        class="mt-2"
        allowDismiss
        :text="errorMessage"
        :inPage="false"
        :backgroundColour="true"
      />
    </v-container>
    <v-container
      fluid
      class="d-flex flex-column px-2"
    >
      <AdsButton
        width="220"
        class="mb-2"
        :primary="true"
        buttonText="Add Supplier"
        icon="mdi-plus"
        @click="createNewSupplier"
        :disabled="isReadOnlyUser"
      />
      <AdsDataTable
        :headers="headers"
        :items="items"
        :footer-props="{
          'items-per-page-options': [20, 30, 50, -1],
        }"
        :items-per-page="20"
        search-label="Find suppliers"
        sort-desc
        @click:row="openServiceSupplier"
      >
        <template v-slot:item.status="{ item }">
          <v-chip
            color="green"
            textColor="white"
            v-if="item.status === 'Active'"
          >
            {{ item.status }}
          </v-chip>
          <v-chip
            color="orange"
            v-if="item.status === 'Deprecated'"
          >
            {{ item.status }}
          </v-chip>
        </template>
      </AdsDataTable>
    </v-container>
  </div>
</template>
<script>
import { Alert, AdsDataTable, AdsButton } from '@nswdoe/doe-ui-core'
import Header from '@/views/ServiceSuppliers/Header/Header'

export default {
  name: 'ServiceSuppliersListing',
  title: 'Suppliers - SAIS Operation UI Portal',
  components: {
    Alert,
    AdsDataTable,
    AdsButton,
    Header,
  },
  data() {
    return {
      headers: [
        { text: 'Supplier Type', value: 'supplierType' },
        { text: 'Code', value: 'supplierCode' },
        { text: 'Name', value: 'name' },
        { text: 'Website', value: 'website' },
        { text: 'Status', value: 'status' },
      ],
      items: [],
    }
  },
  async created() {
    await this.$store.dispatch('moduleSuppliers/fetchSuppliers')
    this.items = this.$store.state.moduleSuppliers.suppliers
  },

  computed: {
    isAdmin() {
      return this.$store.state.isAdmin
    },
    isReadOnlyUser() {
      return this.$store.state.isReadOnlyUser
    },
    errorMessage() {
      return this.$store.state.moduleSuppliers.errorMessage
    },
    isErrorMessage() {
      const errorMessage = this.$store.state.moduleSuppliers.errorMessage
      return !!errorMessage && errorMessage.length > 0
    },
  },

  methods: {
    openServiceSupplier(item) {
      this.$router.push(`/serviceregistry/servicesuppliers/view/${item.supplierId}`)
    },
    createNewSupplier() {
      this.$router.push('/serviceregistry/servicesuppliers/add')
    },
  },
}
</script>
